import { useEffect, useRef, useState } from "react";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import styles from "./DLCReview.module.scss";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNfcSymbol } from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleLeft,
  faAngleRight,
  faPlus,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import ReviewsDimensionsPopUp from "../../components/ReviewsPopUp/ReviewsDimensionsPopUp/ReviewsDimensionsPopUp";
import ReviewsTechnologyPopUp from "../../components/ReviewsPopUp/ReviewsTechnologyPopUp/ReviewsTechnologyPopUp";
import ReviewsDesignPopUp from "../../components/ReviewsPopUp/ReviewsDesignPopUp/ReviewsDesignPopUp";
import ReviewsCustomizationPopUp from "../../components/ReviewsPopUp/ReviewsCustomizationPopUp/ReviewsCustomizationPopUp";
import ReviewsMaterialsPopUp from "../../components/ReviewsPopUp/ReviewsMaterialsPopUp/ReviewsMaterialsPopUp";

import smile from "../../assets/emoji white.png";
import reviewimg from "../../assets/reviewImage.png";
import materialsImg from "../../assets/copertina_materials.png";
import designImg from "../../assets/design.png";

const revBack =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/revBack.png";
const reviewlogo =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/review_logo.png";
const revblackStandard =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/revblack_std.png";
const revblackLogo =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/revblack_logo.png";
const dimensionsImg =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/reviewsDimensions.png";
const technologyImg =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/reviewsTechnology.png";

const customizationImg =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/reviewsCustomization.png";

const DLCReview: React.FC = () => {
  const { t } = useTranslation();
  const redirect = useNavigate();
  const rightDivRef = useRef<null | HTMLDivElement>(null);
  const [scrollTop, setScrollTop] = useState(0);
  const ref = useRef<null | HTMLDivElement>(null);
  const refImageMob = useRef<null | HTMLDivElement>(null);
  const refMobile = useRef<null | HTMLDivElement>(null);

  const [imageN, setImageN] = useState(0);
  const [dimensionsPopUp, setDimensionsPopUp] = useState(false);
  const [technologyPopUp, setTechnologyPopUp] = useState(false);
  const [designPopUp, setDesignPopUp] = useState(false);
  const [customizationPopUp, setCustomizationPopUp] = useState(false);
  const [materialsPopUp, setMaterialsPopUp] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = () => {
    setImageN(2);
  };

  const handleClickInfo = () => {
    // setImageN(2)
    ref.current !== null &&
      ref.current!.scrollIntoView({ block: "end", behavior: "smooth" });
  };

  const handleClickMobileShop = () => {
    window.location.href = "https://shop.dlctech.it/collections/all";
  };

  const handleScroll = () => {
    let vDistance = window.scrollY;

    if (rightDivRef.current) {
      console.log(rightDivRef.current.getBoundingClientRect().top, vDistance);
      setScrollTop(vDistance);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const upImage = () => {
    if (imageN < 4) {
      setImageN(imageN + 1);
    } else {
      setImageN(0);
    }
  };

  const downImage = () => {
    if (imageN > 0) {
      setImageN(imageN - 1);
    } else {
      setImageN(4);
    }
  };
  const handleCustomizeMobile = () => {
    setImageN(2);
    refImageMob.current !== null &&
      refImageMob.current!.scrollIntoView({ block: "end", behavior: "smooth" });
  };

  return (
    <>
      <div className="bg-black ">
        <Header dark={true} selected="Products" />
        <div
          className="d-md-flex d-none  flex-row align-items-start justify-content-around col-12  p-7"
          style={{ backgroundColor: "black" }}
        >
          <div
            className="col-7  d-flex flex-column align-items-center justify-content-center  "
            style={{ width: "60%" }}
          >
            <div
              className={`col-12   d-flex flex-column align-items-center justify-content-end ${styles.space} `}
            ></div>
            <div className="col-12 d-flex flex-column align-items-center justify-content-center">
              <span className="col-12 bigger-title-esquare color-dark_grey_50">
                DLC
              </span>
              <span className="col-12 bigger-title-esquare color-white">
                REVIEW
              </span>
            </div>
            <div className="col-12 mt-7 d-flex flex-row align-items-start justify-content-start">
              <div
                className="col-2  d-flex flex-column align-items-start justify-content-start "
                style={{ marginInlineEnd: "10%" }}
              >
                <div className="col-12">
                  <img
                    src={smile}
                    style={{ height: "35px" }}
                    alt="reviewssmile"
                  />
                </div>
                <div className="col-12 mt-2">
                  <span className="sub-title-ml fw-normal color-white pe-5">
                    {parse(t(`dlcreview_ecofriendly`))}
                  </span>
                </div>
              </div>

              <div
                className="col-2  d-flex flex-column align-items-start justify-content-start "
                style={{ marginInlineEnd: "10%" }}
              >
                <div className="col-12" style={{ height: "35px" }}>
                  <FontAwesomeIcon
                    icon={faNfcSymbol as IconProp}
                    className="color-white"
                    style={{ fontSize: "35px" }}
                  />
                </div>
                <div className="col-10 mt-2">
                  <span className="sub-title-ml fw-normal color-white pe-5 ">
                    {parse(t(`dlcreview_nfc`))}
                  </span>
                </div>
              </div>
              <div className="col-2  d-flex flex-column align-items-start justify-content-start ">
                <div className="col-12" style={{ height: "35px" }}>
                  <FontAwesomeIcon
                    icon={faQrcode as IconProp}
                    className="color-white"
                    style={{ fontSize: "35px" }}
                  />
                </div>
                <div className="col-10 mt-2">
                  <span className="sub-title-ml fw-normal color-white pe-5 ">
                    {parse(t(`dlcreview_qr`))}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="col-12   d-flex flex-column align-items-center justify-content-end "
              style={{ height: "8vh" }}
            ></div>
            {/* <div className="col-12   d-flex flex-column align-items-start justify-content-end ">
              <span className="col-12 sub-title color-dark_grey_50">
                {t(`dlcreview_from`)}
              </span>
              <span className="col-12 sub-title color-white ">
                € 79 / {t(`dlcreview_piece`)}
              </span>
            </div> */}
            <div className="col-12  mt-3 d-flex flex-row align-items-start justify-content-start ">
              <div className="col-10  d-flex flex-row justify-content-between ">
                <span
                  className={`cursor-pointer col-5 sub-title  d-flex flex-row align-items-center justify-content-center ${styles.button}`}
                  onClick={() => handleClick()}
                  style={{ height: "50px", borderRadius: "30px" }}
                >
                  {t(`dlcreview_customize_button`)}
                  {/* Customize your product */}
                </span>
                {/* <span
                  className={`cursor-pointer col-5 sub-title  d-flex flex-row align-items-center justify-content-center ${styles.button}`}
                  onClick={() => handleClickInfo()}
                  style={{ height: "50px", borderRadius: "30px" }}
                >
                  {t(`dlcreview_discover_button`)}
                  More informations
                </span> */}
              </div>
            </div>
            <div style={{ height: "150px" }}></div>
            <div
              className={`col-12  d-flex flex-row align-items-center justify-content-center ${styles.animationContainer}`}
            >
              <img
                src="https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/Reviews-1.mp4"
                alt="reviewsAnimation"
              />
            </div>

            <div className="col-12  mt-7 d-flex flex-row align-items-start justify-content-start ">
              <div className="col-9  d-flex flex-row justify-content-between">
                <span
                  ref={ref}
                  className={`col-xl-5 col-sm-7 sub-title cursor-pointer  d-flex flex-row align-items-center justify-content-center ${styles.button}`}
                  style={{ height: "50px", borderRadius: "30px" }}
                  onClick={() => redirect("/Contact-us")}
                >
                  {t(`dlcreview_quote_button`)}
                  {/* Request a quote */}
                </span>
              </div>
            </div>
          </div>

          <div className={`${styles.imageContainer} `}>
            <div
              className={`col-12    d-flex flex-column  align-items-center justify-content-end ${styles.space}`}
            ></div>

            <div
              className={`${styles.image}  d-flex flex-row align-items-center justify-content-between col-12 `}
            >
              <div className="d-flex  flex-row align-items-center justify-content-center col-1 color-white">
                <FontAwesomeIcon
                  className="cursor-pointer"
                  icon={faAngleLeft as IconProp}
                  style={{ fontSize: "4rem" }}
                  onClick={() => downImage()}
                />
              </div>
              <div className={`col-10  ${styles.imageDiv} `}>
                <img
                  className={`${imageN === 0 ? "d-block" : "d-none"}`}
                  src={reviewimg}
                  alt="reviewsImage"
                />
                <img
                  className={`${imageN === 1 ? "d-block" : "d-none"}`}
                  src={revBack}
                  alt="reviewsImageBack"
                />
                <img
                  className={`${imageN === 2 ? "d-block" : "d-none"}`}
                  src={reviewlogo}
                  alt="reviewsLogoImg"
                />
                <img
                  className={`${imageN === 3 ? "d-block" : "d-none"}`}
                  src={revblackStandard}
                  alt="reviewsBlack"
                />
                <img
                  className={`${imageN === 4 ? "d-block" : "d-none"}`}
                  src={revblackLogo}
                  alt="reviewsBlackLogo"
                />
              </div>
              <div className="d-flex cursor-pointer flex-row align-items-center justify-content-center col-1 color-white">
                <FontAwesomeIcon
                  className="cursor-pointer"
                  icon={faAngleRight as IconProp}
                  style={{ fontSize: "4rem" }}
                  onClick={() => upImage()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 bg-white d-md-block d-none">
          {/* <div className="d-sm-flex d-none border flex-column align-items-center justify-content-start col-12 color-white p-7"> */}
          <Swiper
            className={` p-md-5 p-4  pb-10 ${styles.swiperContainer}`}
            slidesPerView={"auto"}
            spaceBetween={30}
            breakpoints={{
              // 300: {
              //   slidesPerView: 1.2,
              //   spaceBetween: 30,
              // },
              // 430: {
              //   slidesPerView: 1.5,
              //   spaceBetween: 30,
              // },
              // 600: {
              //   slidesPerView: 2.2,
              //   spaceBetween: 30,
              // },
              1024: {
                slidesPerView: 3.5,
                spaceBetween: 30,
              },
            }}
          >
            <SwiperSlide
              className={`${styles.dimensionsSlide}`}
              onClick={() => setDimensionsPopUp(!dimensionsPopUp)}
            >
              <div className={`${styles.innovationBackgroundImage}`}>
                <img src={dimensionsImg} alt="reviewsDimensions" />
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white sub-title ${styles.title} `}
              >
                {parse(t(`Review_quadratoni_titoletto_1`))}
                {/* Dimensions */}
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.text} `}
              >
                {parse(t(`Review_quadratoni_titolo_1`))}
                {/* Simply <br /> Perfect */}
              </div>
              <div className={` color-black col-12  pe-4  ${styles.buttonRow}`}>
                <span className={` ${styles.button}`}>
                  <FontAwesomeIcon
                    icon={faPlus as IconProp}
                    style={{ fontSize: "2.5rem" }}
                  />
                </span>
              </div>
              {/* </div> */}
            </SwiperSlide>
            <ReviewsDimensionsPopUp
              popup={dimensionsPopUp}
              close={() => setDimensionsPopUp(!dimensionsPopUp)}
            />
            <SwiperSlide
              className={`${styles.technologySlide}`}
              onClick={() => setTechnologyPopUp(!technologyPopUp)}
            >
              <div className={`${styles.environmentBackgroundImage}`}>
                <img src={technologyImg} alt="reviewsTechnologyImage" />
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white sub-title ${styles.title} `}
              >
                {parse(t(`Review_quadratoni_titoletto_2`))}
                {/* Technology */}
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.text} `}
              >
                {parse(t(`Review_quadratoni_titolo_2`))}
                {/* SpeedLovers */}
              </div>
              <div className={` color-black col-12  pe-4  ${styles.buttonRow}`}>
                <span className={` ${styles.button}`}>
                  <FontAwesomeIcon
                    icon={faPlus as IconProp}
                    style={{ fontSize: "2.5rem" }}
                  />
                </span>
              </div>
              {/* </div> */}
            </SwiperSlide>
            <ReviewsTechnologyPopUp
              popup={technologyPopUp}
              close={() => setTechnologyPopUp(!technologyPopUp)}
            />
            <SwiperSlide
              className={`  ${styles.customizationSlide}`}
              onClick={() => setMaterialsPopUp(!materialsPopUp)}
            >
              <div className={`${styles.customizationBackgroundImage}`}>
                <img src={materialsImg} alt="reviewsMaterialsImage" />
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white sub-title ${styles.title} `}
              >
                {parse(t(`Review_quadratoni_titoletto_3`))}
                {/* Materials */}
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.text} `}
              >
                {parse(t(`Review_quadratoni_titolo_3`))}
                {/* Build
                <br /> to Last */}
              </div>
              <div className={` color-black col-12  pe-4  ${styles.buttonRow}`}>
                <span className={` ${styles.button}`}>
                  <FontAwesomeIcon
                    icon={faPlus as IconProp}
                    style={{ fontSize: "2.5rem" }}
                  />
                </span>
              </div>
            </SwiperSlide>
            <ReviewsMaterialsPopUp
              popup={materialsPopUp}
              close={() => setMaterialsPopUp(!materialsPopUp)}
            />
            <SwiperSlide
              className={` ${styles.designSlide}`}
              onClick={() => setDesignPopUp(!designPopUp)}
            >
              <div className={`${styles.privacyBackgroundImage}`}>
                <img src={designImg} alt="reviewsDesignImage" />
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white sub-title ${styles.title} `}
              >
                {parse(t(`Review_quadratoni_titoletto_4`))}
                {/* Design */}
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.text} `}
              >
                {parse(t(`Review_quadratoni_titolo_4`))}
                {/* Fits <br /> Everywhere */}
              </div>
              <div className={` color-black col-12  pe-4  ${styles.buttonRow}`}>
                <span className={` ${styles.button}`}>
                  <FontAwesomeIcon
                    icon={faPlus as IconProp}
                    style={{ fontSize: "2.5rem" }}
                  />
                </span>
              </div>
            </SwiperSlide>
            <ReviewsDesignPopUp
              popup={designPopUp}
              close={() => setDesignPopUp(!designPopUp)}
            />
            <SwiperSlide
              className={`  ${styles.customizationSlide}`}
              onClick={() => setCustomizationPopUp(!customizationPopUp)}
            >
              <div className={`${styles.customizationBackgroundImage}`}>
                <img src={customizationImg} alt="reviewsCustomizationImage" />
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white sub-title ${styles.title} `}
              >
                {parse(t(`Review_quadratoni_titoletto_5`))}
                {/* Customization */}
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.text} `}
              >
                {parse(t(`Review_quadratoni_titolo_5`))}
                {/* Make it
                <br /> Yours */}
              </div>
              <div className={` color-black col-12  pe-4  ${styles.buttonRow}`}>
                <span className={` ${styles.button}`}>
                  <FontAwesomeIcon
                    icon={faPlus as IconProp}
                    style={{ fontSize: "2.5rem" }}
                  />
                </span>
              </div>
            </SwiperSlide>
            <ReviewsCustomizationPopUp
              popup={customizationPopUp}
              close={() => setCustomizationPopUp(!customizationPopUp)}
            />
          </Swiper>
          {/* </div> */}
        </div>

        {/* MOBILE */}
        <div className="bg-black  overflow-hidden">
          <div
            className={`col-12 ps-5 d-flex d-md-none  color-light flex-column align-items-start justify-content-start overflow-hidden`}
            // style={{ backgroundColor: "black" }}
          >
            <span className="title-mob-e color-dark_grey_50 col-12 ">DLC</span>
            <span className="title-mob-e color-white  col-12 "> REVIEW</span>
          </div>
          <div
            className={`d-flex bg-black d-md-none p-5 flex-row align-items-center justify-content-center col-12 ${styles.imageDivMob}`}
          >
            <div
              className={` d-flex flex-row align-items-center justify-content-center col-12  ${styles.card}`}
            >
              <div className="d-flex  flex-row align-items-center justify-content-center col-1 color-white">
                <FontAwesomeIcon
                  icon={faAngleLeft as IconProp}
                  style={{ fontSize: "3rem" }}
                  onClick={() => downImage()}
                />
              </div>
              <div className={`col-10 ${styles.mobImageCont} `}>
                <img
                  className={`${imageN === 0 ? "d-block" : "d-none"}`}
                  src={reviewimg}
                  alt="reviewsImage"
                />
                <img
                  className={`${imageN === 1 ? "d-block" : "d-none"}`}
                  src={revBack}
                  alt="reviewsImageBack"
                />
                <img
                  className={`${imageN === 2 ? "d-block" : "d-none"}`}
                  src={revblackStandard}
                  alt="reviewsBlackImage"
                />
                <img
                  className={`${imageN === 3 ? "d-block" : "d-none"}`}
                  src={revblackLogo}
                  alt="reviewsBlackLogo"
                />
                <img
                  className={`${imageN === 4 ? "d-block" : "d-none"}`}
                  src={reviewlogo}
                  alt="reviewsLogo"
                />
              </div>
              <div
                className="d-flex  flex-row align-items-center justify-content-center col-1 color-white"
                ref={refMobile}
              >
                <FontAwesomeIcon
                  icon={faAngleRight as IconProp}
                  style={{ fontSize: "3rem" }}
                  onClick={() => upImage()}
                />
              </div>
            </div>
          </div>

          <div className="col-12   ps-5 pe-5 pb-5  d-flex flex-row align-items-start justify-content-start   text-center  bg-black color-white d-md-none">
            <div className="col-4   d-flex flex-column align-items-start justify-content-start ">
              <div className="col-12 ">
                <img
                  src={smile}
                  alt="smile"
                  style={{ maxHeight: "35px", minHeight: "35px" }}
                />
              </div>
              <div className="col-12 mt-2">
                <span className="sub-title-mob fw-normal color-white">
                  {parse(t(`dlcreview_ecofriendly`))}
                </span>
              </div>
            </div>
            <div className="col-4    d-flex flex-column align-items-start justify-content-start ">
              <div className="col-12">
                <FontAwesomeIcon
                  icon={faNfcSymbol as IconProp}
                  className="color-white"
                  style={{ fontSize: "35px" }}
                />
              </div>
              <div className="col-12 mt-2">
                <span className="sub-title-mob fw-normal color-white">
                  {parse(t(`dlcmenu_nfc`))}
                </span>
              </div>
            </div>
            <div className="col-4   d-flex flex-column align-items-start justify-content-start ">
              <div className="col-12">
                <FontAwesomeIcon
                  icon={faQrcode as IconProp}
                  className="color-white"
                  style={{ fontSize: "35px" }}
                />
              </div>
              <div className="col-12 mt-2">
                <span className="sub-title-mob fw-normal color-white">
                  {parse(t(`dlcmenu_qr`))}
                </span>
              </div>
            </div>
          </div>

          <div className="col-12  d-md-none d-flex flex-row align-items-center justify-content-start bg-black pt-3 flex-wrap p-5">
            <div className="d-flex flex-column align-items-center justify-content-center col-12">
              <div
                className={` col-9 mt-5 ${styles.buttonMobile} `}
                onClick={() => handleClickMobileShop()}
              >
                {t(`dlcreview_customize_button`)}
                {/* Customize your product */}
              </div>
              {/* <div
                className={` col-9 mt-5 ${styles.buttonMobile} `}
                onClick={() => handleClickMobile()}
              >
                {t(`dlcreview_discover_button`)}
                more informations
              </div> */}
            </div>
          </div>
          <div
            className={`${styles.animationContainer}  d-flex d-sm-none flex-row align-items-center justify-content-center`}
          >
            <img
              src="https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/Reviews-1.mp4"
              alt="reviewsAnimation"
            />
          </div>

          <div className="col-12  d-md-none d-flex flex-row align-items-center justify-content-center bg-black pt-3 flex-wrap p-5">
            <div
              className={` col-7 mt-3 ${styles.buttonMobile} `}
              onClick={() => redirect("/Contact-us")}
            >
              {t(`dlcreview_quote_button`)}
              {/* request a quote */}
            </div>
          </div>
          <div className="col-12 bg-white d-md-none d-block">
            {/* <div className="d-sm-flex d-none border flex-column align-items-center justify-content-start col-12 color-white p-7"> */}
            <Swiper
              className=" p-md-5 p-4  pb-10 "
              slidesPerView={"auto"}
              spaceBetween={30}
              breakpoints={{
                300: {
                  slidesPerView: 1.3,
                  spaceBetween: 30,
                },
                430: {
                  slidesPerView: 1.5,
                  spaceBetween: 30,
                },
                600: {
                  slidesPerView: 2.2,
                  spaceBetween: 30,
                },
                // 1024: {
                //   slidesPerView: 3.5,
                //   spaceBetween: 30,
                // },
              }}
            >
              <SwiperSlide
                className={`${styles.dimensionsSlide}`}
                onClick={() => setDimensionsPopUp(!dimensionsPopUp)}
              >
                <div className={`${styles.innovationBackgroundImage}`}>
                  <img src={dimensionsImg} alt="reviewsDimensionsImage" />
                </div>
                <div
                  className={` ps-4 pt-4 d-flex flex-row color-white sub-title ${styles.title} `}
                >
                  {parse(t(`Review_quadratoni_titoletto_1`))}
                  {/* Dimensions */}
                </div>
                <div
                  className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.text} `}
                >
                  {parse(t(`Review_quadratoni_titolo_1`))}
                  {/* Simply <br /> Perfect */}
                </div>
                <div
                  className={` color-black col-12  pe-4  ${styles.buttonRow}`}
                >
                  <span className={` ${styles.button}`}>
                    <FontAwesomeIcon
                      icon={faPlus as IconProp}
                      style={{ fontSize: "2.5rem" }}
                    />
                  </span>
                </div>
                {/* </div> */}
              </SwiperSlide>
              <ReviewsDimensionsPopUp
                popup={dimensionsPopUp}
                close={() => setDimensionsPopUp(!dimensionsPopUp)}
              />
              <SwiperSlide
                className={`${styles.technologySlide}`}
                onClick={() => setTechnologyPopUp(!technologyPopUp)}
              >
                <div className={`${styles.environmentBackgroundImage}`}>
                  <img src={technologyImg} alt="reviewsTechnologyImage" />
                </div>
                <div
                  className={` ps-4 pt-4 d-flex flex-row color-white sub-title ${styles.title} `}
                >
                  {parse(t(`Review_quadratoni_titoletto_2`))}
                  {/* Technology */}
                </div>
                <div
                  className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.text} `}
                >
                  {parse(t(`Review_quadratoni_titolo_2`))}
                  {/* SpeedLovers */}
                </div>
                <div
                  className={` color-black col-12  pe-4  ${styles.buttonRow}`}
                >
                  <span className={` ${styles.button}`}>
                    <FontAwesomeIcon
                      icon={faPlus as IconProp}
                      style={{ fontSize: "2.5rem" }}
                    />
                  </span>
                </div>
                {/* </div> */}
              </SwiperSlide>
              <ReviewsTechnologyPopUp
                popup={technologyPopUp}
                close={() => setTechnologyPopUp(!technologyPopUp)}
              />
              <SwiperSlide
                className={`  ${styles.customizationSlide}`}
                onClick={() => setMaterialsPopUp(!materialsPopUp)}
              >
                <div className={`${styles.customizationBackgroundImage}`}>
                  <img src={materialsImg} alt="reviewsMaterialsImage" />
                </div>
                <div
                  className={` ps-4 pt-4 d-flex flex-row color-white sub-title ${styles.title} `}
                >
                  {parse(t(`Review_quadratoni_titoletto_3`))}
                  {/* Materials */}
                </div>
                <div
                  className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.text} `}
                >
                  {parse(t(`Review_quadratoni_titolo_3`))}
                  {/* Build
                  <br /> to Last */}
                </div>
                <div
                  className={` color-black col-12  pe-4  ${styles.buttonRow}`}
                >
                  <span className={` ${styles.button}`}>
                    <FontAwesomeIcon
                      icon={faPlus as IconProp}
                      style={{ fontSize: "2.5rem" }}
                    />
                  </span>
                </div>
              </SwiperSlide>
              <ReviewsMaterialsPopUp
                popup={materialsPopUp}
                close={() => setMaterialsPopUp(!materialsPopUp)}
              />
              <SwiperSlide
                className={` ${styles.designSlide}`}
                onClick={() => setDesignPopUp(!designPopUp)}
              >
                <div className={`${styles.privacyBackgroundImage}`}>
                  <img src={designImg} alt="reviewsDesignImage" />
                </div>
                <div
                  className={` ps-4 pt-4 d-flex flex-row color-white sub-title ${styles.title} `}
                >
                  {parse(t(`Review_quadratoni_titoletto_4`))}
                  {/* Design */}
                </div>
                <div
                  className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.text} `}
                >
                  {parse(t(`Review_quadratoni_titolo_4`))}
                  {/* Fits <br /> Everywhere */}
                </div>
                <div
                  className={` color-black col-12  pe-4  ${styles.buttonRow}`}
                >
                  <span className={` ${styles.button}`}>
                    <FontAwesomeIcon
                      icon={faPlus as IconProp}
                      style={{ fontSize: "2.5rem" }}
                    />
                  </span>
                </div>
              </SwiperSlide>
              <ReviewsDesignPopUp
                popup={designPopUp}
                close={() => setDesignPopUp(!designPopUp)}
              />
              <SwiperSlide
                className={`  ${styles.customizationSlide}`}
                onClick={() => setCustomizationPopUp(!customizationPopUp)}
              >
                <div className={`${styles.customizationBackgroundImage}`}>
                  <img src={customizationImg} alt="reviewsCustomizationImage" />
                </div>
                <div
                  className={` ps-4 pt-4 d-flex flex-row color-white sub-title ${styles.title} `}
                >
                  {parse(t(`Review_quadratoni_titoletto_5`))}
                  {/* Customization */}
                </div>
                <div
                  className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.text} `}
                >
                  {parse(t(`Review_quadratoni_titolo_5`))}
                  {/* Make it
                  <br /> Yours */}
                </div>
                <div
                  className={` color-black col-12  pe-4  ${styles.buttonRow}`}
                >
                  <span className={` ${styles.button}`}>
                    <FontAwesomeIcon
                      icon={faPlus as IconProp}
                      style={{ fontSize: "2.5rem" }}
                    />
                  </span>
                </div>
              </SwiperSlide>
              <ReviewsCustomizationPopUp
                popup={customizationPopUp}
                close={() => setCustomizationPopUp(!customizationPopUp)}
              />
            </Swiper>
            {/* </div> */}
          </div>
        </div>
      </div>
      <Footer dark={false} />
    </>
  );
};
export default DLCReview;
